@import "./Colors.scss";

.file-upload-box {
  margin-left: 10%;
  margin-right: 10%;
  .file-upload-button {
    width: 100%;
    background-color: $blue-lighter;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($blue-lighter, 10%);
    }
  }
}
