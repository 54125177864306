
.a4-2 {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 842px;
  padding: 38px 21px;
  width: 595px;
}
.a4-2 .date-of-test {
  height: 19px;
  left: 26px;
  letter-spacing: 0px;
  line-height: 24px;
  opacity: 0.5;
  position: absolute;
  top: 172px;
  white-space: nowrap;
  width: 90px;
}
.a4-2 .ellipse-1 {
  height: 37px;
  left: 0px;
  position: absolute;
  top: -1px;
  transform: rotate(-90deg);
  width: 36px;
}
.a4-2 .group-27 {
  align-items: flex-start;
  display: flex;
  height: 36px;
  justify-content: flex-end;
  left: 119px;
  min-width: 185px;
  opacity: 0.2;
  position: absolute;
  top: 92px;
  transform: rotate(90deg);
}
.a4-2 .group-28 {
  top: 94px;
}
.a4-2 .group-29 {
  top: 146px;
}
.a4-2 .group-30 {
  align-items: flex-start;
  display: flex;
  height: 31px;
  left: 273px;
  min-width: 144px;
  position: absolute;
  top: 50px;
}
.a4-2 .group-303 {
  align-items: flex-start;
  display: flex;
  height: 31px;
  left: 273px;
  min-width: 144px;
  position: absolute;
  top: 105px;
}
.a4-2 .group-31 {
  align-items: flex-start;
  display: flex;
  height: 216px;
  margin-left: 5px;
  margin-top: 31px;
  min-width: 504px;
}
.a4-2 .group-5 {
  align-items: flex-start;
  display: flex;
  height: 36px;
  justify-content: center;
  left: 67px;
  min-width: 36px;
  position: absolute;
  top: 0px;
}
.a4-2 .group-5-1 {
  align-items: flex-end;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 36px;
  justify-content: flex-end;
  left: 67px;
  min-width: 36px;
  position: absolute;
  top: 0px;
}
.a4-2 .group-6 {
  align-items: flex-start;
  display: flex;
  margin-left: 14.12px;
  min-width: 185px;
}
.a4-2 .image-7 {
  height: 80px;
  left: 20px;
  position: absolute;
  top: 26px;
  width: 80px;
}
.a4-2 .barcode-image {
  width:500px;
  top: 520px;
  position: absolute;
}
.a4-2 .name {
  height: 17px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 24px;
  position: absolute;
  top: 14px;
  white-space: nowrap;
  width: 142px;
}
.a4-2 .overlap-group {
  height: 139px;
  margin-right: 1px;
  position: relative;
  width: 490px;
}
.a4-2 .overlap-group-1 {
  height: 36px;
  margin-top: -0px;
  position: relative;
  width: 186px;
}
.a4-2 .overlap-group-2 {
  height: 31px;
  position: relative;
  width: 142px;
}
.a4-2 .overlap-group1 {
  height: 36px;
  margin-bottom: -0px;
  position: relative;
  width: 37px;
}
.a4-2 .overlap-group2 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  height: 36px;
  justify-content: flex-end;
  min-width: 37px;
  padding: 9px 9.5px;
}
.a4-2 .overlap-group3 {
  height: 217px;
  margin-top: -0.5px;
  position: relative;
  width: 500px;
}
.a4-2 .phone {
  height: 17px;
  left: 26px;
  letter-spacing: 0px;
  line-height: 24px;
  position: absolute;
  top: 186px;
  white-space: nowrap;
  width: 68px;
}
.a4-2 .place {
  height: 19px;
  left: 0px;
  letter-spacing: 0px;
  line-height: 24px;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  white-space: nowrap;
  width: 142px;
}
.a4-2 .rectangle-53 {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  height: 216px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 500px;
}
.a4-2 .rectangle-54 {
  height: 217px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 166px;
}
.a4-2 .text-1 {
  height: 87px;
  left: 62px;
  letter-spacing: 0px;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 369px;
}
.a4-2 .text-2 {
  left: 0px;
  letter-spacing: 0px;
  line-height: 22px;
  opacity: 0.7;
  position: absolute;
  text-align: center;
  top: 74px;
  width: 490px;
}
.a4-2 .text-3 {
  letter-spacing: 0px;
  line-height: 22px;
  margin-right: 9px;
  margin-top: 167px;
  min-height: 22px;
  opacity: 0.7;
  white-space: nowrap;
  width: 490px;
}
.a4-2 .text-4 {
  align-self: flex-end;
  letter-spacing: 0px;
  line-height: 22px;
  margin-top: 71px;
  min-height: 22px;
  opacity: 0.7;
  white-space: nowrap;
  width: 526px;
}
.a4-2 .vax-pass {
  height: 17px;
  left: 0px;
  position: absolute;
  top: 10px;
  width: 186px;
}
.a4-2 .vax-pass-1 {
  height: 186px;
  left: 84px;
  position: absolute;
  top: -74px;
  transform: rotate(-90deg);
  width: 17px;
}
.a4-2 .vector {
  height: 18px;
  width: 18px;
}
.a4-2 .vector-1 {
  height: 18px;
  left: 10px;
  position: absolute;
  top: 9px;
  transform: rotate(-90deg);
  width: 18px;
}
.a4-2 .vector-10 {
  height: 1px;
  margin-right: 1px;
  margin-top: 35px;
  width: 496px;
  margin-bottom: -10px;
}
.a4-2 .vector-11 {
  height: 1px;
  left: 259px;
  position: absolute;
  top: 82px;
  width: 217px;
}
.a4-2 .vector-11-1 {
  height: 1px;
  margin-right: 1px;
  margin-top: 31px;
  width: 496px;
}
.a4-2 .vector-12 {
  height: 1px;
  left: 259px;
  position: absolute;
  top: 137px;
  width: 217px;
}
.avenir-roman-normal-black-12px {
  color: black;
  font-family: "Avenir-Roman", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.avenir-roman-normal-black-6px {
  color: black;
  font-family: "Avenir-Roman", Helvetica;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
}
.avenir-roman-normal-white-12px {
  color: white;
  font-family: "Avenir-Roman", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.avenir-roman-normal-white-6px {
  color: white;
  font-family: "Avenir-Roman", Helvetica;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
}
.montserrat-bold-gravel-26px {
  color: var(--gravel);
  font-family: "Montserrat", Helvetica;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-normal-gravel-12px {
  color: var(--gravel);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.montserrat-normal-gravel-14px {
  color: var(--gravel);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
:root {
  --gravel: rgba(73, 73, 73, 1);
}
.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
