.vax-intro-screen {
  .intro-screen-img {
    height: 35vh;
  }

  .vax-pass-text {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-align: center;
    display: inline;
  }

  .vax-pass-img-caption {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-align: center;
  }

  .divider {
    border-top: 1px solid white;
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0.3;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }

  .text-light {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-align: center;
  }

  .title-text {
    justify-content: center;
  }
}
