.vax-rounded-button-container {
  justify-content: center;
  .vax-rounded-button-blue {
    background: $blue-lighter;
    /* Button Shadow */

    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.07),
      inset 0px 1px 0px rgba(255, 255, 255, 0.2);
    border-radius: 31px;
    border: none;
    padding: 20px;
    width: 80%;
    color: white;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($blue-lighter, 10%);
      box-shadow: 2px 4px 4px rgba(255, 255, 255, 0.1),
        inset 0px 1px 0px rgba(255, 255, 255, 0.2);
    }
  }
}

.vax-rounded-button-container {
  justify-content: center;
  .vax-rounded-button-blue {
    background: $blue-lighter;
    /* Button Shadow */

    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.07),
      inset 0px 1px 0px rgba(255, 255, 255, 0.2);
    border-radius: 31px;
    border: none;
    padding: 20px;
    width: 80%;
    color: white;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($blue-lighter, 10%);
      box-shadow: 2px 4px 4px rgba(255, 255, 255, 0.1),
        inset 0px 1px 0px rgba(255, 255, 255, 0.2);
    }
  }

  .vax-rounded-button-black {
    background: black;
    /* Button Shadow */

    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.07),
      inset 0px 1px 0px rgba(255, 255, 255, 0.2);
    border-radius: 31px;
    border: none;
    padding: 20px;
    width: 80%;
    color: white;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten(black, 25%);
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1),
        inset 0px 1px 0px rgba(0, 0, 0, 0.2);
    }
  }
  .vax-rounded-button-outline-white {
    background: none;
    /* Button Shadow */

    border-radius: 31px;
    border: 1px solid white;
    padding: 20px;
    width: 80%;
    color: white;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 2px 4px 4px rgba(255, 255, 255, 0.2),
        inset 0px 1px 0px rgba(255, 255, 255, 0.3);
      text-decoration: underline;
    }
  }
}
