.vax-error-screen {
  .image-wrapper {
    background-color: white;
    border-radius: 50%;
    width: 300px;
    height: 300px;

    .logo-img {
      animation: floating 5s ease-in-out infinite;
    }
  }
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
