@import "./Colors.scss";

.welcome-screen-large-button {
  text-align: center;
  margin: auto;
  width: 80%;
  background-color: $white;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.07),
    inset 0px 1px 0px rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 5px;
  transition: transform 0.5s;
  cursor: pointer;

  &:hover {
    background-color: darken($white, 5%);
    transform: scale(1.1);
  }

  .title {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: center;
    color: $blue-main;
  }

  .subtitle {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: center;
    color: $dark-gray;
  }
}
