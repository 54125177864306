@import "./Colors.scss";
@import "./Font.scss";
@import "./Text.scss";
@import "./Buttons.scss";
@import "./Animations.scss";

.vax-container {
  flex: 1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  background-color: $blue-dark;
  min-height: 100vh;
}

.vax-inner-container {
  background-color: $blue-dark;
  margin-top: 30px;
  width: 420px;
  min-height: 600px;
  margin-bottom: 30px;
}

.select-provider-inner-container {
  background-color: $blue-dark;
  margin-top: 30px;
  width: 600px;
  min-height: 600px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .vax-inner-container {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 100%;
  }

  .vax-container {
    background-color: $blue-dark;
  }

  .select-provider-inner-container {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 100%;
  }
}
