@import "./Font.scss";

.vax-container {
  h5 {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.1em;
    text-align: center;
    color: white;
  }

  h6 {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.1em;
    text-align: center;
    color: white;
  }

  p {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    color: white;
  }

  .white-text {
    color: white;
  }

  a {
    text-decoration: underline;
    text-decoration-color: white;
  }
}
