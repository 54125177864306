.vax-loading-screen {
  .loader {
    position: relative;
    max-width: 100%;

    .spinner {
      border: 10px solid transparent; /* Light grey */
      border-top: 10px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 250px;
      height: 250px;
      animation: spin 2s linear infinite;
      position: absolute;
      top: 0px;
      left: 0px;
    }
    .logo-img {
      width: 250px;
      height: 250px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
