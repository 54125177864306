@import "./Colors.scss";

.vax-header {
  display: flex;
  position: relative;

  .logo {
    margin: 30px auto;
  }

  .back-button {
    position: fixed;
    left: -25px;
    top: -11px;

    .arrow {
      padding-top: 10px;
    }

    .quarter-circle-top-left {
      width: 60px;
      height: 60px;
      background: $blue-lighter;
      border-radius: 0 0 75px 0;
      -moz-border-radius: 0 0 75px 0;
      -webkit-border-radius: 0 0 75px 0;
      transition: all 0.3s ease-in;
      cursor: pointer;
    }

    &:hover,
    &:focus,
    &:active {
      .quarter-circle-top-left {
        background-color: $blue-light;
        width: 75px;
        height: 75px;
      }
      .arrow {
        animation: rotate 0.5s ease-in-out;
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
